





import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'IndexPage',
  components: {
    HomePage: () => import('./home.vue'),
    ServicesPage: () => import('./services.vue')
  }
})
export default class extends Vue {
  asyncData({ env }) {
    return {
      website: env.website
    }
  }
}
